module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ben Boonen Loodgieter","short_name":"Ben Boonen","lang":"nl","start_url":"/","background_color":"#fff","theme_color":"#e3000f","display":"minimal-ui","icon":"src/images/icon-ben-boonen-safe-area.inline.svg","icon_options":{"purpose":"maskable"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
